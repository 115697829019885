import React from 'react';
import { Button, Stack } from '@mui/material';

function GameControls() {
  const handlePause = () => {
    // Implement pause functionality
  };

  const handleResume = () => {
    // Implement resume functionality
  };

  const handleSave = () => {
    // Implement save functionality
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="center" marginTop={2}>
      <Button variant="contained" color="primary" onClick={handlePause}>
        Pause
      </Button>
      <Button variant="contained" color="secondary" onClick={handleResume}>
        Resume
      </Button>
      <Button variant="contained" color="success" onClick={handleSave}>
        Save Game
      </Button>
    </Stack>
  );
}

export default GameControls;
