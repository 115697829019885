import React from 'react';
import { Container, Typography } from '@mui/material';
import ChessboardComponent from './components/Chessboard';
import GameControls from './components/GameControls';
import EngineConnection from './components/EngineConnection';

function App() {
  return (
    <Container maxWidth="md">
      <Typography variant="h3" align="center" gutterBottom>
        Chess Web Application
      </Typography>
      <ChessboardComponent />
      <GameControls />
      <EngineConnection />
    </Container>
  );
}

export default App;
