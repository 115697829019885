import React, { useState } from 'react';
import { TextField, Button, Stack } from '@mui/material';

function EngineConnection() {
  const [engineAddress, setEngineAddress] = useState('');

  const handleConnect = () => {
    // Implement engine connection logic
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="center" marginTop={2}>
      <TextField
        label="Engine Address"
        variant="outlined"
        value={engineAddress}
        onChange={(e) => setEngineAddress(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleConnect}>
        Connect Engine
      </Button>
    </Stack>
  );
}

export default EngineConnection;
