import React, { useState, useEffect } from 'react';
import { Chessboard } from 'react-chessboard';
import { Chess } from 'chess.js';
import io from 'socket.io-client';

const socket = io('/');

function ChessboardComponent() {
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState('start');

  useEffect(() => {
    socket.on('move', (move) => {
      game.move(move);
      setFen(game.fen());
    });
  }, [game]);

  const onDrop = (sourceSquare, targetSquare) => {
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q',
    });

    if (move === null) return false;

    setFen(game.fen());
    socket.emit('move', move);
    return true;
  };

  return (
    <Chessboard position={fen} onPieceDrop={onDrop} />
  );
}

export default ChessboardComponent;
